@font-face {
  font-family: 'font';
  src: url('../public/PinkyLove.otf') format('opentype');
}


html {
  background-color: pink;
  height: 100%;
  overflow: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.home {
  width: 100%;
  
  margin: 0 0 0 0;
  font-family: 'font';
}

.App {
  
  width: 100vw;
  
  cursor: url('../public/heartfilled1.png') 20 20, auto; /* Set the default custom cursor */
  position: relative; /* Important for absolute positioning inside */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
 
}


.cursor-trail {
  position: absolute;
  pointer-events: none; /* Make sure the cursor trail doesn't interfere with other mouse events */
  width: 40px;
  height: 40px;
  background-image: url('../public/heartfilled1.png');
  background-size: cover;
  z-index: 1000; /* Adjust based on your z-index needs */
  opacity: 0; /* Start invisible */
  animation: cursor-trail 0.5s forwards;
}

@keyframes cursor-trail {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0); /* Keep the trail close to the cursor */
    opacity: 0;
  }
}


.video-container {
  position: relative;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Hide everything outside the container */
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1; /* Ensure the video stays in the background */
}

.content {
  position: relative;
  z-index: 2; /* Ensure content is above the video */
  text-align: center; /* Center the text */
  top: 50%;
  transform: translateY(-50%);
  color: white; /* Set text color for visibility against dark backgrounds */
}


.divdiv {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  width: 100%;
  z-index: 100;
}

.divdiv2 {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 100;
  margin-top: -20px;
}

.title {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40%;
  width: 100%;
  z-index: 100;
  margin-top: 350px;
  font-family: Arial, sans-serif;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  font-family: 'font';
  font-size: 50px;
}

.social-links a {
  padding: 10px 20px;
  margin: 0 10px;
  text-decoration: none;
  color: #fff;
  background-color: #ff009d;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  animation: pulse 2s infinite ease-in-out;
}

.social-links a:hover {
  background-color: #ff51a8;
  animation: explode 0.5s ease forwards;
  cursor: url('../public/heartfilled1.png') 20 20, auto;
}

.separator {
  margin: 0 5px;
  color: #333;
}

@keyframes explode {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px #ff009d;
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px #ff009d;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px #ff009d;
  }
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.9;
  }
}





.section {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  font-family: 'font';
  animation: shadowPulse 3s infinite ease-in-out;
  position: relative;
  isolation: isolate;
  transition: all var(--spring-duration) var(--spring-easing);
}

.section:hover:not(:active) {
  transition-duration: calc(var(--spring-duration)*0.5);
  transform: scale(1.03);
}

.section:active {
  transform: scale(0.97);
  transition-duration: calc(var(--spring-duration)*0.5);
}


.section::before {
  /* Your shimmer styles, ensure it covers the .section */
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  border-radius: 10px; /* Match your .section border-radius */
  z-index: 0;
  animation: shimmer 1s linear infinite both;
}



.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.section-content {
  font-size: 18px;
  color: #666;
}